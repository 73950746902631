<template>
  <div class="content-wrap profit-content-wrap">
    <bread-menu title="收益管理">
      <div class="nav-title">
        <!-- <router-link to="" tag="span" class="active">收益概况</router-link> -->
        <router-link to="{path:''}" tag="span" class="active">收益列表</router-link>
      </div>
    </bread-menu>
    <div>
        <router-view></router-view>
    </div>
  </div>
</template>

<script>
import BreadMenu from "../../components/breadMenu.vue";
export default {
  components: {
    BreadMenu,
  },
  data() {
    return {
      value1: "",
    };
  },
};
</script>